// src/Level1.js
import './App.css';
import { Input, Button, ConfigProvider, message } from 'antd';
import axios from 'axios';
import { useState } from 'react';

function HJdjieur323e39() {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const api = process.env.REACT_APP_API + '/check-input';
      const response = await axios.post(api, { inputValue });
      const data = response.data;

      if (data.success) {
        message.success(data.message);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error('There was an error submitting the form.');
      console.error('Error submitting input:', error);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#000',
          colorBgBase: '#fff',
          colorTextBase: '#000',
        },
      }}
    >
      <div className="App">
        <header className="App-header">
          <Input
            className="input-box"
            placeholder="Enter here"
            value={inputValue}
            onChange={handleInputChange}
            style={{ width: 300, marginBottom: 10 }}
          />
          <Button type="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </header>
      </div>
    </ConfigProvider>
  );
}

export default HJdjieur323e39;
