import React, { useState, useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import { ChromePicker } from 'react-color';
import './Uficdvkcmchdl.css';

function Uficdvkcmchdl() {
  const [showTypewriter, setShowTypewriter] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [showSymbol, setShowSymbol] = useState(false);
  const [symbolColor, setSymbolColor] = useState('#000000'); // Changed to white
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [elementsVisible, setElementsVisible] = useState(true);
  const [backgroundTransition, setBackgroundTransition] = useState(false);
  const [showFinalText, setShowFinalText] = useState(false); // Show final text after background transition
  const [reaperUrl, setReaperUrl] = useState(''); 
  const [audio] = useState(() => {
    const newAudio = new Audio('/audio.mp3');
    newAudio.loop = true;
    return newAudio;
  });

  useEffect(() => {
    // Fetch the URL from the backend
    const fetchReaperUrl = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/reaper-url`);
        const data = await response.json();
        setReaperUrl(data.url);
      } catch (error) {
        console.error('Error fetching Reaper URL:', error);
      }
    };

    fetchReaperUrl();
  }, []);


  useEffect(() => {
    const playAudio = () => {
      if (audio.paused) {
        audio
          .play()
          .then(() => {
            console.log('Audio playing successfully.');
          })
          .catch((error) => {
            console.error('Audio playback failed:', error);
          });
      }
    };

    window.addEventListener('click', playAudio, { once: true });

    return () => {
      window.removeEventListener('click', playAudio);
    };
  }, [audio]);

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/validate-input-song`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ input: inputValue }),
        });

        const data = await response.json();

        if (data.success) {
          setShowTypewriter(true);
        } else {
          console.log('Invalid input');
        }
      } catch (error) {
        console.error('Error validating input:', error);
      }
    }
  };

  const handleColorChange = (color) => {
    setSymbolColor(color.hex);
    if (color.hex.toLowerCase() === '#888888') {
      console.log('Success: The user selected the correct color!');
      setTimeout(() => {
        setFadeOut(true);
      }, 5000);
    }
  };
  
  const handleFadeOutEnd = () => {
    setTimeout(() => {
      setBackgroundTransition(true);
      setTimeout(() => {
        setElementsVisible(false); // Delay hiding elements
        setShowFinalText(true); // Show final text after transition
      }, 6000); // Delay for background transition
    }, 500);
  };

  return (
    <div
      className={`uficdvkcmchdl-container ${
        backgroundTransition ? 'transition-bg' : ''
      }`}
    >
      {elementsVisible && (
        <>
          <input
            type="text"
            className={`invisible-input ${fadeOut ? 'fade-out' : ''}`}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={handleKeyPress}
            onAnimationEnd={fadeOut ? handleFadeOutEnd : null}
          />

          {showTypewriter && (
            <div className={`typewriter-text-small ${fadeOut ? 'fade-out' : ''}`}>
              <Typewriter
                options={{
                  delay: 50,
                  cursor: '_',
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString(`Through mortal days, we turn and sway,<br>
                    From dawn's first light to twilight’s gray.<br>
                    In each life’s thread, both tight and slack,<br>
                    Death holds the cord, yet pulls not back.<br>
                    To life’s last step, a boundless grace,<br>
                    Both vast and still, a quiet fade.`)
                    .callFunction(() => setShowSymbol(true))
                    .start();
                }}
              />
            </div>
          )}

          {showSymbol && (
            <div
              className="unicode-symbol"
              style={{ color: symbolColor }}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              ✝
            </div>
          )}

          {showColorPicker && (
            <div className={`color-picker-container ${fadeOut ? 'fade-out' : ''}`}>
              <ChromePicker
                color={symbolColor}
                onChange={handleColorChange}
                disableAlpha
              />
            </div>
          )}
        </>
      )}

{!elementsVisible && showFinalText && (
        <div className="final-text">
          <div className="typewriter-final">
            <Typewriter
              options={{
                delay: 75,
                cursor: '_',
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString('release the virus')
                  .pauseFor(3000)
                  .callFunction(() => setShowSymbol(true))
                  .typeString('<br>')
                  .typeString(
                    `<a href="${reaperUrl}" target="_blank" rel="noopener noreferrer">Reaper.exe</a>`
                  )
                  .start();
              }}
            />
          </div>
          <div
            className="unicode-symbol"
            style={{ color: symbolColor }}
            onClick={() => setShowColorPicker(!showColorPicker)}
          >
            ✝
          </div>
        </div>
      )}
    </div>
  );
}

export default Uficdvkcmchdl;
