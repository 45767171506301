import React, { useState } from 'react';
import './Cfdsiofjdsffjsd.css'; // Import CSS for styling

function Cfdsiofjdsffjsd() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    thoughts: '',
  });
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await fetch(`${process.env.REACT_APP_API}/send-email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        to: 'lbrnth.game@gmail.com',
        subject: 'LBRNTH Game 5 Feedback',
        text: `
          First Name: ${formData.firstName}
          Last Name: ${formData.lastName}
          Email: ${formData.email}
          Thoughts: ${formData.thoughts}
        `,
      }),
    });

    setLoading(false);

    if (response.ok) {
      setEmailSent(true);
    } else {
      alert('Failed to send email.');
    }
  };

  return (
    <div className="cfdsiofjdsffjsd-container">
      <div>
        <h1>Congratulations, you have reached the end of LBRNTH Game 5</h1>
        <p className="instructions">
          Please complete the form below. If you’re the first to beat the game, you’ll receive an email notification shortly.
          The winner will also be announced on Discord and via email.
        </p>
        {emailSent ? (
          <p>Thank you for your feedback! Your submission has been received.</p>
        ) : (
          <form className="game-feedback-form" onSubmit={handleSubmit}>
            <label>
              First Name:
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Last Name:
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              General Thoughts:
              <textarea
                name="thoughts"
                value={formData.thoughts}
                onChange={handleChange}
              ></textarea>
            </label>
            <button type="submit" disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default Cfdsiofjdsffjsd;
