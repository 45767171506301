import React, { useState, useEffect } from 'react';
import './Login.css';

function Login({ onLogin }) {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [timeRemaining, setTimeRemaining] = useState('');
  const [showPasswordInput, setShowPasswordInput] = useState(false);

  useEffect(() => {
    const targetDate = new Date('November 10, 2024 14:00:00 GMT-0500'); // Adjust your time zone as needed

    const updateCountdown = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        setTimeRemaining("00d 00h 00m 00s");
        onLogin(); // Automatically log in when countdown reaches zero
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTimeRemaining(
        `${days}d ${hours.toString().padStart(2, '0')}h ${minutes.toString().padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`
      );
    };

    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [onLogin]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/check-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
      });

      const data = await response.json();

      if (data.success) {
        onLogin();
      } else {
        setError('Incorrect password');
      }
    } catch (err) {
      console.error('Error:', err);
      setError('Something went wrong');
    }
  };

  return (
    <div className="login-container">
      <div className="login-logo"></div> {/* Logo added above countdown */}
      <div style={{ fontFamily: 'Snowstorm', color: 'white', fontSize: '36px', marginBottom: '20px' }}>
        {timeRemaining}
      </div>

      {!showPasswordInput ? (
        <button 
          className="login-button" 
          onClick={() => setShowPasswordInput(true)} 
          style={{ marginBottom: '20px' }}
        >
          Admin Access
        </button>
      ) : (
        <form onSubmit={handleSubmit} className="login-form">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
            placeholder="Enter Password"
          />
          <button type="submit" className="login-button">
            Login
          </button>
          {error && <p style={{ color: 'red', fontFamily: 'Snowstorm' }}>{error}</p>}
        </form>
      )}
    </div>
  );
}

export default Login;
