import React, { useState, useEffect } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import Typewriter from 'typewriter-effect';
import 'react-h5-audio-player/lib/styles.css';
import './Rdfjsdlkfdsdf.css';

const Rdfjsdlkfdsdf = () => {
  const russianAlphabet = 'АБВГДЕЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ'.split('');
  const [letters, setLetters] = useState(['Д', 'Я', 'Б', 'Г', 'И', 'Ю', 'Ф', 'Ц', 'Э']);
  const [targetWord, setTargetWord] = useState([]);
  const [englishWord, setEnglishWord] = useState([]);
  const [puzzleSolved, setPuzzleSolved] = useState(false);
  const [showTypewriter, setShowTypewriter] = useState(false);
  const [displayEnglish, setDisplayEnglish] = useState(false);
  const [showInputBox, setShowInputBox] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const fetchWords = async () => {
      try {
        const targetResponse = await fetch(`${process.env.REACT_APP_API}${process.env.REACT_APP_TARGET_WORD_ENDPOINT}`);
        const englishResponse = await fetch(`${process.env.REACT_APP_API}${process.env.REACT_APP_ENGLISH_WORD_ENDPOINT}`);

        const targetData = await targetResponse.json();
        const englishData = await englishResponse.json();

        setTargetWord(targetData);
        setEnglishWord(englishData);
      } catch (error) {
        console.error('Error fetching words:', error);
      }
    };

    fetchWords();
  }, []);

  useEffect(() => {
    if (JSON.stringify(letters) === JSON.stringify(targetWord)) {
      setPuzzleSolved(true);
      setTimeout(() => {
        setShowTypewriter(true);
      }, 3000); // 3-second delay
    }
  }, [letters, targetWord]);

  useEffect(() => {
    if (showTypewriter) {
      setTimeout(() => {
        setShowInputBox(true);
      }, 4000); // Delay after typewriter finishes
    }
  }, [showTypewriter]);

  const changeLetter = (index, direction) => {
    setLetters((prev) => {
      const currentLetterIndex = russianAlphabet.indexOf(prev[index]);
      const newIndex = (currentLetterIndex + direction + russianAlphabet.length) % russianAlphabet.length;
      const newLetters = [...prev];
      newLetters[index] = russianAlphabet[newIndex];
      return newLetters;
    });
  };

  const handleLetterClick = () => {
    if (puzzleSolved) {
      setDisplayEnglish(true);
      const link = document.createElement('a');
      link.href = '/textbook.pdf';
      link.download = 'textbook.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setInputValue(value);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/validate-input`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ input: value }),
      });
  
      const data = await response.json();
  
      if (data.success && data.redirectUrl) {
        window.location.href = data.redirectUrl;
      }
    } catch (error) {
      console.error('Error validating input:', error);
    }
  };
  

  return (
    <div className="rdfjsdlkfdsdf-container">
      <div
        className={`puzzle-container ${puzzleSolved ? 'solved' : ''}`}
        onClick={handleLetterClick}
      >
        {(displayEnglish ? englishWord : letters).map((letter, index) => (
          <div key={index} className="letter-box">
            {!puzzleSolved && <button onClick={() => changeLetter(index, 1)}>&uarr;</button>}
            <div className="letter-display">{letter}</div>
            {!puzzleSolved && <button onClick={() => changeLetter(index, -1)}>&darr;</button>}
          </div>
        ))}
      </div>

      {!puzzleSolved && (
        <>
          <AudioPlayer
            src="/audio.wav"
            autoPlay={false}
            style={{ width: '300px' }}
            customAdditionalControls={[]}
            customVolumeControls={[]}
            showJumpControls={false}
          />
          <a href="/audio.wav" download className="download-button">Download Audio</a>
        </>
      )}

      {showTypewriter && (
        <div className="typewriter-container">
          <Typewriter
            options={{
              strings: ['what is the winning move?'],
              autoStart: true,
              loop: false,
              deleteSpeed: Infinity,
            }}
          />
        </div>
      )}

      {showInputBox && (
        <div className="input-box-container">
          <input
            type="text"
            className="input-box"
            value={inputValue}
            onChange={handleInputChange}
          />
        </div>
      )}
    </div>
  );
};

export default Rdfjsdlkfdsdf;
