import React, { useState } from 'react';
import { Progress } from 'antd';
import { useNavigate } from 'react-router-dom';
import './D4rhxjdk9dn3.css';

function D4rhxjdk9dn3() {
  const [code, setCode] = useState(['', '', '', '']);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('active');
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API;

  const handleInputChange = async (value, index) => {
    if (!/^\d$/.test(value) && value !== '') return;

    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    const filledDigits = newCode.filter((digit) => digit !== '').length;
    const newProgress = (filledDigits / 4) * 100;
    setProgress(newProgress);

    if (filledDigits === 4) {
      try {
        const response = await fetch(`${API_URL}/check-password-2`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ password: newCode.join('') }),
        });
        const data = await response.json();

        if (data.success) {
          setStatus('success');
          console.log('Correct combination entered!');
          setTimeout(() => {
            navigate(data.redirectUrl);
          }, 1000);
        } else {
          setStatus('exception');
          console.log('Incorrect combination.');
        }
      } catch (error) {
        console.error('Error verifying the code:', error);
      }
    } else {
      setStatus('active');
    }
  };

  return (
    <div className="d4rhxjdk9dn3-container">
      <div className="padlock">
        {code.map((digit, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleInputChange(e.target.value, index)}
            className="padlock-digit"
          />
        ))}
      </div>
      <Progress
        percent={progress}
        status={status}
        showInfo={false}
        style={{ width: '200px', marginTop: '20px' }}
      />
      <div className="image-container">
        <img src="/poster.jpg" alt="Poster" className="poster-image" />
      </div>
    </div>
  );
}

export default D4rhxjdk9dn3;
