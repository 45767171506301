import React, { useState, useEffect } from 'react';

function Jewifj34022deq() {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [isXNext, setIsXNext] = useState(true);
  const [xMoveSequence, setXMoveSequence] = useState([]);
  const [showClue, setShowClue] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [spots, setSpots] = useState({ restrictedSpots: [], requiredSequence: [] });

  useEffect(() => {
    const fetchSpots = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/get-spots`);
        const data = await response.json();
        setSpots(data);
      } catch (error) {
        console.error('Error fetching spots:', error);
      }
    };

    fetchSpots();
  }, []);

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setIsXNext(true);
    setXMoveSequence([]);
    setShowClue(false);
    setAnswer(null);
  };

  const handleClick = (index) => {
    if (
      board[index] ||
      calculateWinner(board) ||
      !isXNext ||
      showClue
    ) return;

    const newBoard = board.slice();
    newBoard[index] = 'X';
    setBoard(newBoard);
    setIsXNext(false);

    const newSequence = [...xMoveSequence, index];
    setXMoveSequence(newSequence);

    if (calculateWinner(newBoard) === 'X' && !hasPlayedCorrectSequence(newSequence)) {
      resetGame();
      return;
    }

    if (newSequence.length === spots.requiredSequence.length && newSequence.join() === spots.requiredSequence.join()) {
      fetchAnswer();
      setShowClue(true);
      return;
    }

    setTimeout(() => handleBotMove(newBoard), 500);
  };

  const handleBotMove = (currentBoard) => {
    if (showClue || calculateWinner(currentBoard)) return;

    const availableSpots = currentBoard
      .map((value, index) => (value === null && !spots.restrictedSpots.includes(index) ? index : null))
      .filter((index) => index !== null);

    if (availableSpots.length === 0) {
      resetGame();
      return;
    }

    const botMoveIndex = availableSpots[Math.floor(Math.random() * availableSpots.length)];
    currentBoard[botMoveIndex] = 'O';
    setBoard(currentBoard);
    setIsXNext(true);
  };

  const calculateWinner = (board) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (let line of lines) {
      const [a, b, c] = line;
      if (board[a] && board[a] === board[b] && board[a] === board[c]) {
        return board[a];
      }
    }
    return null;
  };

  const hasPlayedCorrectSequence = (sequence) => {
    return sequence.length === spots.requiredSequence.length && sequence.join() === spots.requiredSequence.join();
  };

  const fetchAnswer = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API}/get-answer-2`);
      const data = await response.json();
      setAnswer(data.answer);
    } catch (error) {
      console.error('Error fetching the answer:', error);
    }
  };

  const winner = calculateWinner(board);
  const status = winner ? `Winner: ${winner}` : null;

  return (
    <div style={styles.container}>
      {showClue ? (
        <h1 style={styles.clue}>{`${answer}`}</h1>
      ) : (
        <>
          {status && <p style={styles.text}>{status}</p>}
          <div style={styles.board}>
            {board.map((cell, index) => (
              <div key={index} style={{ ...styles.cell, ...getCellBorderStyle(index) }} onClick={() => handleClick(index)}>
                {cell}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

const getCellBorderStyle = (index) => {
  const borderStyle = {
    borderTop: index >= 3 ? '2px solid white' : 'none',
    borderLeft: index % 3 !== 0 ? '2px solid white' : 'none',
  };
  return borderStyle;
};

const styles = {
  container: {
    backgroundColor: 'black',
    color: 'white',
    fontFamily: '"Snowstorm", sans-serif',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: '1rem',
    marginBottom: '20px',
  },
  board: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 100px)',
    gridTemplateRows: 'repeat(3, 100px)',
    gap: '0',
  },
  cell: {
    width: '100px',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    fontSize: '2em',
    cursor: 'pointer',
    color: 'white',
    fontFamily: '"Snowstorm", sans-serif',
  },
  clue: {
    fontSize: '2rem',
    color: 'white',
  },
};

export default Jewifj34022deq;
