// Xfoiwefcn823
import React, { useState, useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import './Xfoiwefcn823.css'; // Import CSS for styling

function Xfoiwefcn823() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    // Calculate the duration of the typewriter effect based on text length and typing speed
    const text = `Sasha Volkov, the charismatic leader, is no mere preacher; he is the executor of his father Nikolai’s chilling legacy. Nikolai, a former Soviet scientist obsessed with transcending mortality, fled to the U.S. and perfected a horrifying breakthrough: the ability to digitize human consciousness. Under the guise of spiritual rebirth, the church recruits vulnerable volunteers, transforming them into vessels for the wealthy and desperate—individuals willing to pay any price for a second chance at life. These vessels, bound by cables to the church’s hidden servers, are the lifelines for disembodied consciousnesses, their bodies little more than shells for another’s immortality. This grotesque cycle feeds a growing empire of exploitation, one that cannot be allowed to spread. The only way to stop it is to strike at its core: a cyber weapon, capable of erasing the stored consciousnesses and severing the Volkov’s control for good. The clock is ticking, and the fate of countless lives—both living and digital—hangs in the balance.`;
    const typingSpeed = 40; 
    const typingDuration = text.length * typingSpeed;
  
    // Show button after the typewriter finishes
    const timeout = setTimeout(() => {
      setShowButton(true);
    }, typingDuration);
  
    return () => clearTimeout(timeout); // Cleanup timeout on component unmount
  }, []);
  

  return (
    <div className="xfoiwefcn823-container">
      <div className="typewriter-text-3">
        <Typewriter
          options={{
            strings: [
              'Sasha Volkov, the charismatic leader, is more than a preacher; he is the executor of his father Nikolai\'s chilling legacy. Nikolai, a former Soviet scientist obsessed with transcending mortality, fled to the U.S. and achieved a horrifying breakthrough: the digitization of human consciousness. Under the guise of offering spiritual rebirth, the church recruits vulnerable individuals, transforming them into vessels for the wealthy and desperate—those willing to pay any price for a second chance at life. These vessels, tethered to the church’s hidden servers, serve as lifelines for disembodied consciousnesses. Their bodies become mere shells, housing another’s quest for immortality. This grotesque cycle fuels an empire of exploitation that threatens to expand unchecked. To stop it, a cyber weapon must be deployed—one capable of erasing the stored consciousnesses and severing the Volkovs\' control. Time is running out, and the fate of countless lives, both living and digital, hangs in the balance.'
            ],
            autoStart: true,
            loop: false,
            delay: 20, // Typing speed
            deleteSpeed: Infinity, // Prevent backspacing
          }}
        />
      </div>

      {showButton && (
        <button
          className="continue-button"
          onClick={() => {
            window.location.href = '/#/Uficdvkcmchdl';
          }}
        >
          Continue to final stage
        </button>
      )}
    </div>
  );
}

export default Xfoiwefcn823;
