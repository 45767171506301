import React, { useState, useEffect } from 'react';
import Typewriter from 'typewriter-effect';

function Home() {
    const [showLogo, setShowLogo] = useState(false);
    const [showTypewriter, setShowTypewriter] = useState(false);
    const [typingCompleted, setTypingCompleted] = useState(false);
    const [typewriterDelay, setTypewriterDelay] = useState(false);
    const [showFinalTypewriter, setShowFinalTypewriter] = useState(false);

    useEffect(() => {
        // Delay logo appearance for 1 second
        const logoTimer = setTimeout(() => {
            setShowLogo(true);
        }, 1000);
        
        // Delay typewriter by 2 seconds
        const typewriterTimer = setTimeout(() => {
            setTypewriterDelay(true);
        }, 2000);

        return () => {
            clearTimeout(logoTimer);
            clearTimeout(typewriterTimer);
        };
    }, []);

    const handleStartGameClick = () => {
        const audio = new Audio('/intro.mp3');
        audio.play();

        const startGameText = document.getElementById('start-game-text');
        if (startGameText) {
            startGameText.remove();
            startCharacterCycling();
            swapLBRNTHCharacters();
        }

        const logo = document.getElementById('logo');
        if (logo) {
            logo.style.opacity = '0';
        }
    };

    const startCharacterCycling = () => {
        const characters = ['L', 'B', 'R', 'N', 'T', 'H'];
        characters.forEach((char, index) => {
            const charElement = document.getElementById(`char-${index}`);
            if (charElement) {
                let cycleInterval = setInterval(() => {
                    const randomChar = String.fromCharCode(65 + Math.floor(Math.random() * 26));
                    charElement.textContent = randomChar;
                }, 50);
                charElement.dataset.cycleInterval = cycleInterval;
            }
        });
    };

    const swapLBRNTHCharacters = () => {
        const newCharacters = ['Л', 'Б', 'Р', 'Н', 'Т', 'Х'];
        newCharacters.forEach((newChar, index) => {
            const delay = index === 0 ? 500 : 500 * (index + 1);
            setTimeout(() => {
                const charElement = document.getElementById(`char-${index}`);
                if (charElement) {
                    clearInterval(charElement.dataset.cycleInterval);
                    charElement.textContent = newChar;
                }
                if (index === newCharacters.length - 1) {
                    setTimeout(() => {
                        fadeOutCharacters();
                    }, 3000);
                }
            }, delay);
        });
    };

    const fadeOutCharacters = () => {
        const characters = document.querySelectorAll('[id^="char-"]');
        characters.forEach((charElement) => {
            charElement.style.transition = 'opacity 8s';
            charElement.style.opacity = '0';
        });
        setTimeout(() => {
            setShowTypewriter(true);
        }, 9000);
    };

    useEffect(() => {
        if (typingCompleted) {
            setTimeout(() => {
                const fadeTextElements = document.querySelectorAll('.fade-text');
                fadeTextElements.forEach((element) => {
                    element.style.transition = 'opacity 8s';
                    element.style.opacity = '0';
                });

                // Wait for fade-out of other text
                setTimeout(() => {
                    const highlightElement = document.getElementById('highlight-text');
                    if (highlightElement) {
                        setTimeout(() => {
                            highlightElement.style.transition = 'opacity 5s';
                            highlightElement.style.opacity = '0';
                        }, 5000); // Wait for 5 seconds before fading out

                        setTimeout(() => {
                            setShowFinalTypewriter(true); // Show final typewriter after fade out
                        }, 10000); // 5s wait + 5s fade duration
                    }
                }, 8000); // Fade duration for fade-text elements
            }, 7000);
        }
    }, [typingCompleted]);

    return (
        <div
            className="Home"
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                position: 'relative',
            }}
        >
            {showLogo && (
                <img
                    id="logo"
                    src="/logo.png"
                    alt="Logo"
                    style={{
                        position: 'absolute',
                        top: '30%',
                        width: '100px',
                        height: 'auto',
                        opacity: '1',
                        boxShadow: '2px 2px 5px rgba(0,0,0,0.5)',
                        zIndex: '10',
                    }}
                />
            )}
            <div
                style={{
                    position: 'absolute',
                    top: '40%',
                    width: '100%',
                    textAlign: 'center',
                }}
            >
                {typewriterDelay && (
                    <div
                        className="typewriter-text"
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Typewriter
                            options={{
                                autoStart: true,
                                loop: false,
                                cursor: '',
                                delay: 100,
                            }}
                            onInit={(typewriter) => {
                                typewriter
                                    .typeString(
                                        '<span id="char-0" style="font-size: 2.3em; display: inline-block; width: 1em; text-align: center;">L</span>'
                                    )
                                    .pauseFor(100)
                                    .typeString(
                                        '<span id="char-1" style="font-size: 2.3em; display: inline-block; width: 1em; text-align: center;">B</span>'
                                    )
                                    .pauseFor(100)
                                    .typeString(
                                        '<span id="char-2" style="font-size: 2.3em; display: inline-block; width: 1em; text-align: center;">R</span>'
                                    )
                                    .pauseFor(100)
                                    .typeString(
                                        '<span id="char-3" style="font-size: 2.3em; display: inline-block; width: 1em; text-align: center;">N</span>'
                                    )
                                    .pauseFor(100)
                                    .typeString(
                                        '<span id="char-4" style="font-size: 2.3em; display: inline-block; width: 1em; text-align: center;">T</span>'
                                    )
                                    .pauseFor(100)
                                    .typeString(
                                        '<span id="char-5" style="font-size: 2.3em; display: inline-block; width: 1em; text-align:center;">H</span>'
                                    )
                                    .pauseFor(1000)
                                    .typeString(
                                        '<br><span style="font-size: 1em; font-weight: normal; cursor: pointer;" id="start-game-text">START GAME</span>'
                                    )
                                    .callFunction(() => {
                                        const startGameText = document.getElementById('start-game-text');
                                        if (startGameText) {
                                            startGameText.addEventListener('click', handleStartGameClick);
                                        }
                                    })
                                    .start();
                            }}
                        />
                    </div>
                )}
            </div>
            {showTypewriter && (
                <div
                    className="typewriter-text"
                    style={{
                        padding: '15em',
                        fontSize: '1.5em',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typewriter
                        options={{
                            autoStart: true,
                            loop: false,
                            cursor: '',
                            delay: 40,
                        }}
                        onInit={(typewriter) => {
                            typewriter
                                .typeString(
                                    '<span class="fade-text">In the tranquil village of Camden, Maine, the charismatic Sasha Volkov presides over the </span>'
                                )
                                .typeString(
                                    '<span class="highlight" id="highlight-text">New Dawn Church of Christ</span>'
                                )
                                .typeString(
                                    '<span class="fade-text">, a thriving Christian community dedicated to charity, sacrifice, and hopeful visions of everlasting life. Yet, beneath its pious facade lies an undercurrent of mystery. The church is guarding a truth few dare to unearth.</span>'
                                )
                                .callFunction(() => {
                                    setTypingCompleted(true);
                                })
                                .start();
                        }}
                    />
                </div>
            )}
            {showFinalTypewriter && (
                <div
                    style={{
                        position: 'absolute',
                        textAlign: 'center',
                        fontSize: '1.8em',
                        color: 'white',
                        fontFamily: 'Snowstorm',
                    }}
                >
                    <Typewriter
                        options={{
                            autoStart: true,
                            loop: false,
                            cursor: '',
                            delay: 60,
                        }}
                        onInit={(typewriter) => {
                            typewriter.typeString('https://www.newdawnchurchofchrist.com').start();
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default Home;
