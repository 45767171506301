// App.js
import './App.css';
import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Jewifj34022deq from './Jewifj34022deq';
import Login from './Login';
import D4rhxjdk9dn3 from './D4rhxjdk9dn3';
import Gdmeuijd82e from './Gdmeuijd82e';
import HJdjieur323e39 from './HJdjieur323e39';
import Uficdvkcmchdl from './Uficdvkcmchdl';
import Wdkjsalkjsad from './Wdkjsalkjsad';
import Cfdsiofjdsffjsd from './Cfdsiofjdsffjsd';
import Rdfjsdlkfdsdf from './Rdfjsdlkfdsdf';
import Xfoiwefcn823 from './Xfoiwefcn823';


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if user is already authenticated
    const auth = localStorage.getItem('isAuthenticated');
    if (auth) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  return (
    <Routes>
      {isAuthenticated ? (
        <>
          <Route path="/" element={<Home />} />
          <Route path="/jewifj34022deq" element={<Jewifj34022deq />} />
          <Route path="/d4rhxjdk9dn3" element={<D4rhxjdk9dn3 />} />
          <Route path="/gdmeuijd82e" element={<Gdmeuijd82e />} />
          <Route path="/hJdjieur323e39" element={<HJdjieur323e39 />} />
          <Route path="/uficdvkcmchdl" element={<Uficdvkcmchdl />} />
          <Route path="/wdkjsalkjsad" element={<Wdkjsalkjsad />} />
          <Route path="/cfdsiofjdsffjsd" element={<Cfdsiofjdsffjsd />} />
          <Route path="/rdfjsdlkfdsdf" element={<Rdfjsdlkfdsdf />} />
          <Route path="/xfoiwefcn823" element={<Xfoiwefcn823 />} />
        </>
      ) : (
        <Route path="*" element={<Login onLogin={handleLogin} />} />
      )}
    </Routes>
  );
}

export default App;