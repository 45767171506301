import React, { useState, useEffect, useRef } from 'react';
import './Wdkjsalkjsad.css'; // Import CSS for styling
import { Modal, Button } from 'antd';
import Typewriter from 'typewriter-effect';

function Wdkjsalkjsad() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [terminalLines, setTerminalLines] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isTerminalReady, setIsTerminalReady] = useState(false);
  const [isTyping, setIsTyping] = useState(false); // Tracks typing status
  const terminalRef = useRef(null);
  const inputRef = useRef(null); // Ref for input element
  const endOfTerminalRef = useRef(null); // Ref for scrolling
  const [expectingEmail, setExpectingEmail] = useState(false);
  const [showInput, setShowInput] = useState(true);
  const audioRef = useRef(new Audio(`${process.env.PUBLIC_URL}/theme.mp3`));
  const [targetEmail, setTargetEmail] = useState(''); // State for target email
  const [redirectUrl, setRedirectUrl] = useState(''); // State for redirect URL

  const showModal = () => {
    setIsModalOpen(true);
    audioRef.current.play();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setTerminalLines([]);
    setInputValue('');
    setIsTerminalReady(false);
    setIsTyping(false);
  };

  const handleCommand = (command) => {
    const newLines = [...terminalLines, `>[USER]: ${command}`];
    setIsTyping(true); // Disable input while typing

    const addTypewriterLine = (typeString, delay = 0) => (
      <Typewriter
        key={newLines.length}
        options={{
          delay: 10,
          cursor: '',
        }}
        onInit={(typewriter) => {
          setTimeout(() => {
            typewriter
              .typeString(typeString)
              .callFunction(() => {
                setIsTyping(false);
                if (endOfTerminalRef.current) {
                  endOfTerminalRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to bottom
                }
              })
              .start();
          }, delay);
        }}
      />
    );

    if (expectingEmail) {
      if (command.toLowerCase() === targetEmail.toLowerCase()) {
        newLines.push(addTypewriterLine(`[REAPER]: RAT sent.`));
        newLines.push(addTypewriterLine(`Waiting for connection..`, 1000));
        newLines.push(addTypewriterLine(`Waiting for connection..`, 2000));
        newLines.push(addTypewriterLine(`Loading..`, 3000));
        newLines.push(addTypewriterLine(`Loading..`, 4000));
        newLines.push(addTypewriterLine(`Connection Established.`, 5000));
        newLines.push(addTypewriterLine(`Loading…`, 6000));
        newLines.push(addTypewriterLine(`Loading…`, 7000));
        newLines.push(addTypewriterLine(`Connection Terminated.`, 8000));
        newLines.push(addTypewriterLine(`Server has been wiped via remote access trojan.`, 9000));

        setShowInput(false); // Hide input and user prompt

        // Redirect to Google after 5 seconds from the last message
        setTimeout(() => {
          window.location.href = redirectUrl;
        }, 15000); // 9000ms for last message + 5000ms = 14000ms total
      } else {
        newLines.push(addTypewriterLine(`[REAPER]: Phishing attempt failed. Provided email inactive. Operation aborted.`));
      }
      setExpectingEmail(false); // Reset state
      setTerminalLines(newLines);
      setInputValue('');
      return;
    }

    switch (command.toLowerCase()) {
      case 'help':
        newLines.push(addTypewriterLine(`\n[REAPER]:\n- help: Provides available commands.\n- rat: Execute remote access trojan\n`));
        break;
      case 'rat':
        newLines.push(addTypewriterLine(`[REAPER]: Input target email address.`));
        setExpectingEmail(true); // Set state to expect email input
        break;
      default:
        newLines.push(addTypewriterLine(`[REAPER]: Command '${command}' not recognized. Enter 'help' for a list of commands.\n`));
    }

    setTerminalLines(newLines);
    setInputValue('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (inputValue.trim() && !isTyping) {
        handleCommand(inputValue.trim());
      }
    }
  };

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [terminalLines]);

  // Automatically focus input when typing finishes
  useEffect(() => {
    if (!isTyping && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isTyping]);

  useEffect(() => {
    if (endOfTerminalRef.current) {
      endOfTerminalRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [terminalLines]);

  useEffect(() => {
    // Fetch the target email from the backend
    const fetchTargetEmail = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/get-target-email`);
        const data = await response.json();
        setTargetEmail(data.email);
      } catch (error) {
        console.error('Error fetching target email:', error);
      }
    };

    const fetchRedirectUrl = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/redirect-url-2`);
        const data = await response.json();
        setRedirectUrl(data.url);
      } catch (error) {
        console.error('Error fetching redirect URL:', error);
      }
    };

    fetchTargetEmail();
    fetchRedirectUrl();
  }, []);

  return (
    <div className="Wdkjsalkjsad-container">
      <Button onClick={showModal} style={{ marginTop: '20px' }}>
        Launch Reaper
      </Button>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        centered
        className="command-prompt-modal"
      >
        <div className="command-prompt" ref={terminalRef}>
          {terminalLines.map((line, index) => (
            <div key={index}>{line}</div>
          ))}
          {isModalOpen && terminalLines.length === 0 && (
            <Typewriter
              options={{
                delay: 10,
                cursor: '',
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString('// INITIALIZING SYSTEM PROTOCOLS...\n')
                  .pauseFor(500)
                  .typeString('// WELCOME TO REAPER v4.2.1\n\n************\n\n')
                  .typeString('[REAPER]: Initializing...\n')
                  .pauseFor(1000)
                  .typeString('[REAPER]: System ready.\n')
                  .callFunction(() => {
                    setIsTerminalReady(true);
                  })
                  .start();
              }}
            />
          )}
          {isTerminalReady && showInput && (
            <div className="input-line">
              <span className="prompt-symbol">[USER]:</span>
              <input
                ref={inputRef} // Attach ref to input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                className="terminal-input"
                autoFocus
                disabled={isTyping} // Disable input during typing
              />
            </div>
          )}
          {/* Dummy div for automatic scrolling */}
          <div ref={endOfTerminalRef} />
        </div>
      </Modal>
    </div>
  );
}

export default Wdkjsalkjsad;
